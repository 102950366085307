import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";
import Contact from "../components/contact";

const ContactPage = (props) => {
  const { errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Container>
        <h1>Contact</h1>
        <Contact />
      </Container>
    </Layout>
  );
};

export default ContactPage;
