import React from "react";

import * as styles from "./container.module.css";

const Container = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
};

export default Container;
