import React from "react";

import * as styles from "./contact.module.css";

function Contact() {
  return (
    <div className={styles.root}>
      <div>
        <p className={styles.icon}>[mail] johnnyhuynhvo@gmail.com</p>
        <p className={styles.icon}>[tlf] +47 468 31 455</p>
        <p className={styles.icon}>
          [linkedin]{" "}
          <a href="https://www.linkedin.com/in/johnnyhuynhvo/">johnnyhuynhvo</a>
        </p>
        <p className={styles.icon}>
          [instagram]{" "}
          <a href="https://www.instagram.com/johnnyhuynhvo/">johnnyhuynhvo</a>
        </p>
        <h2 style={{ marginBottom: "50px" }}>Don´t be shy! Say hello!</h2>
      </div>
      <img src="/wave.gif" alt="Gif of a man waving" width="700" />
    </div>
  );
}

export default Contact;
